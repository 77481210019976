<template>
  <div class="previewer-wrapper" :class="extraClass">
    <div
      v-if="clickable"
      oncontextmenu="return false;"
      style="min-height: 100%"
      v-show="html"
    >
      <iframe
        id="iframe"
        class="iframe border-1 border-color--grey rounded-10 pa-2"
        @load="onIframeLoad(this)"
        :src="previews"
      ></iframe>
    </div>
    <div
      v-else
      oncontextmenu="return false;"
      style="min-height: 100%"
      v-show="html"
    >
      <iframe
        id="iframe"
        class="iframe border-1 border-color--grey rounded-10 pa-2"
        @load="onIframeLoad(this)"
        :src="previews"
        style="pointer-events: none"
      ></iframe>
    </div>
    <empty v-show="!html || html.length < 1" :headline="emptyText"></empty>
  </div>
</template>

<script>
export default {
  name: "HtmlPreviewer",
  props: {
    value: { type: String, default: "" },
    emptyText: { type: String, default: "No preview yet" },
    extraClass: { type: String, default: "" },
    clickable: { type: Boolean, default: true },
  },
  data: () => ({
    html: "",
  }),
  computed: {
    previews() {
      if (this.html && this.html.trim() !== "")
        return this.getBlobURL(this.html, "text/html");
      return "";
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.html = val;
      },
      immediate: true,
      deep: true,
    },
    html(val) {
      this.$emit("input", val);
    },
  },
  methods: {
    onIframeLoad(iframe) {
      if (iframe) {
        iframe.height = iframe.contentWindow.document.body.scrollHeight + "px";
      }
    },
    getBlobURL(code, type) {
      const blob = new Blob([code], { type });
      return URL.createObjectURL(blob);
    },
  },
};
</script>

<style lang="scss" >
.previewer-wrapper {
  height: auto;
  width: 100% !important;

  .iframe {
    width: 100% !important;
    min-height: 98vh;
    border: none;
  }
}
</style>